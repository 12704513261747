<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Calculate the pH of a buffer system that contains
        <latex-number :number="baseConc.toFixed(3)" unit="\text{M}" />
        of
        <chemical-latex content="NH3(aq)" />
        and
        <latex-number :number="acidConc.toFixed(3)" unit="\text{M}" />
        of
        <chemical-latex content="NH4Cl(aq)\text{.}" />
        Note that the
        <stemble-latex content="$\text{K}_\text{b}$" />
        value of
        <chemical-latex content="NH3(aq)" />
        is
        <stemble-latex content="$1.80\times 10^{-5}.$" style="white-space: nowrap" />
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH:}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the change in pH if
        <number-value :value="volStrong" unit="\text{mL}" />
        of
        <stemble-latex content="$\text{0.500 M HCl}$" />
        is added to
        <stemble-latex content="$\text{0.0400 L}$" />
        of the buffer system described in part a). Make sure you use the correct number of sig figs.
      </p>

      <calculation-input
        v-model="inputs.DeltapH1"
        prepend-text="$\Delta\text{pH:}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Determine the change in pH if
        <number-value :value="volStrong" unit="\text{mL}" />
        of
        <stemble-latex content="$\text{0.500 M NaOH}$" />
        is added to
        <stemble-latex content="$\text{0.0400 L}$" />
        of the buffer system described in part a). Make sure you use the correct number of sig figs.
      </p>

      <calculation-input
        v-model="inputs.DeltapH2"
        prepend-text="$\Delta\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question148',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pH: null,
        DeltapH1: null,
        DeltapH2: null,
      },
    };
  },
  computed: {
    acidConc() {
      return this.taskState.getValueBySymbol('acidConc').content.toFloat();
    },
    baseConc() {
      return this.taskState.getValueBySymbol('baseConc').content.toFloat();
    },
    volStrong() {
      return this.taskState.getValueBySymbol('volStrong').content;
    },
  },
};
</script>
